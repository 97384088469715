import React from 'react'
import { Helmet } from 'react-helmet'

import '@/styles/scalehack/styleCo.scss'
import favicon from '@/images/scalehack/favicon.ico'
import appleIcon from '@/images/scalehack/apple-touch-icon.png'
import { useLocation } from '@reach/router'

type Props = {
  title: string
  url: string
}

export const ScalehackHead = (props: Props): JSX.Element => {
  const { title } = props
  const pathname = useLocation().pathname

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="Scalehackでは、「スケールアップを、シンプルに。」をミッションに、スケールアップを総合支援するスケールアップスタジオ事業（Scalehackシリーズ）を展開しています。"
      />
      <meta property="og:title" content={title} />
      <meta
        property="og:url"
        content={`https://relic-holdings.co.jp/group/scalehack`}
      />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630_ScaleHack.png"
      />
      <meta
        property="og:description"
        content="Scalehackでは、「スケールアップを、シンプルに。」をミッションに、スケールアップを総合支援するスケールアップスタジオ事業（Scalehackシリーズ）を展開しています。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      {/contact|document/.test(pathname) && (
        <meta name="robots" content="noindex" />
      )}
      <meta
        name="google-site-verification"
        content="DiKmS795or4np_Qq0LTAgRtobsPyAP7R_ccwDH17_dg"
      />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
      <script src="https://sdk.form.run/js/v2/formrun.js"></script>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-15H7VZHWNX"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-15H7VZHWNX');
        `}
      </script>
      <script src="https://www.google.com/recaptcha/api.js"></script>
    </Helmet>
  )
}
