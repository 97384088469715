import { HeaderNavListType } from '@/typedir/types'

export const headerNavList: HeaderNavListType[] = [
  { url: '/group/scalehack', label: 'トップ', labelEnglish: 'TOP' },
  {
    url: '/group/scalehack/#news',
    label: 'お知らせ',
    labelEnglish: 'NEWS'
  },
  {
    url: '/group/scalehack/#service',
    label: 'サービス',
    labelEnglish: 'OUR SERVICE'
  },
  {
    url: '/group/scalehack/#release',
    label: 'リリース予定',
    labelEnglish: 'COMING SOON'
  },
  {
    url: '/group/scalehack/#company',
    label: '会社情報',
    labelEnglish: 'ABOUT US'
  },
  {
    url: 'https://relic-holdings.co.jp/group/scalehack/contact/',
    label: 'お問い合わせ',
    isContact: true
  }
]
