import React from 'react'
import { css } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { ScalehackHead } from '@/components/scalehack/scalehackHead'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header/scalehack'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer/scalehack'
import { ScalehackContact } from '@/components/scalehack/scalehackContact'
import header_scalehack_logo from '@/images/scalehack/logo-corporate.svg'

export default function Contact(): JSX.Element {
  return (
    <>
      <ScalehackHead
        title="お問い合わせ| 株式会社Scalehack"
        url="group/scalehack/contact"
      />
      <HeaderNav
        css={cssHeaderNav}
        url="/group/scalehack"
        imgSrc={header_scalehack_logo}
        alt="Scale Hack"
        navList={headerNavList}
        gradientColor={[color.orange4, color.orange4]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.black2}
        headerLogoCss={cssHeaderLogoSize}
      />
      <ScalehackContact />
      <FooterBottom css={cssBgColor} footerBottomList={footerBottomList} />
    </>
  )
}

const cssHeaderNav = css`
  padding: 15px 16px;
  font-family: 'Jost', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  box-shadow: 0 3px 6px ${color.gray1};
  ${mq.pc} {
    box-shadow: 0 0 16px ${color.gray1};
  }
`

const cssHeaderLogoSize = css`
  width: 144px;
  ${mq.mid} {
    width: 195px;
    margin-bottom: -6px;
  }
`

const cssBtnFontStyle = css`
  font-weight: 600;
  color: ${color.black2};
`

const cssBgColor = css`
  background-color: #f3f1ef;
`
