import React from 'react'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'

import '@/styles/scalehack/styleCo.scss'

import { ScalehackForm } from '@/components/scalehack/scalehackForm'

export const ScalehackContact = (): JSX.Element => {
  return (
    <>
      <section css={cssSection}>
        <h1 css={cssHeading}>
          <span css={cssHeadingEn}>CONTACT</span>
          <span css={cssHeadingJp}>お問い合わせ</span>
        </h1>
        <ScalehackForm />
      </section>
    </>
  )
}

// Section
const cssSection = css`
  width: 100%;
  max-width: 632px;
  padding: 96px 16px 48px;
  margin: 0 auto;
  color: ${color.black2};

  ${mq.pc} {
    padding: 172px 16px 120px;
  }
`

// Heading
const cssHeading = css`
  padding-bottom: 38px;
  text-align: center;

  ${mq.pc} {
    padding-bottom: 46px;
  }
`

const cssHeadingEn = css`
  display: block;
  padding-bottom: 9px;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 4.32px;

  ${mq.pc} {
    font-size: 54px;
  }
`

const cssHeadingJp = css`
  display: block;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 2.8px;

  ${mq.pc} {
    font-size: 28px;
  }
`
